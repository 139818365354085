import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout, t } from '../components/PanelLayout';
import { inject, observer } from 'mobx-react';
import { Form, Button, Input, Card, Upload, Affix, TimePicker, Radio, Spin, Modal, message, Row, Col, Divider } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, CameraOutlined, MenuOutlined, CopyOutlined, EditOutlined, EditFilled } from "@ant-design/icons";
import { useDynamicList, useRequest, useToggle } from "ahooks";
import { format, eachDayOfInterval, addDays } from 'date-fns'
import { pl as localePl } from 'date-fns/locale'
import ReactDragListView from 'react-drag-listview';
import { ApiShop, Api, getShopId } from '../Api';
import { keys, filter, toPairs, countBy, flatMap, sortBy, padStart, reverse, concat } from 'lodash'
import config from '../config'
import { handleSaveError } from '../utils'

import {
  LangSwitcher
} from './Common'

const AddButton = ({ onClick, text }) => (
  <Button
    icon={<PlusCircleOutlined />}
    block
    type="dashed"
    onClick={onClick}
    style={{
      maxWidth: 600,
      margin: 12,
    }}
  >
    {text}
  </Button>
)

const ImageField = ({ name, disabled }) => {
  return <Form.Item noStyle shouldUpdate>
    {({ getFieldValue, prefixName }) => {
      //dependencies={name}
      const fieldName = concat(prefixName, name)
      const img = getFieldValue(fieldName)

      console.log("img", img)
      return <Form.Item
        style={{ marginBottom: 0, marginRight: 12 }}
        name={name}
        getValueProps={v => (v && {
          fileList: [{
            url: `${config.frontend_url}/cust-images/${getShopId()}/${v}.jpg`,
          }]
        })}
        normalize={v => {
          return v && v[v.length - 1]?.response?.id
        }}
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}

      >
        <Upload
          listType="picture"
          showUploadList={false}
          action={`/api/shop/${getShopId()}/images`}
          disabled={disabled}
        >
          {img
            ? <img
              src={`${config.frontend_url}/cust-images/${getShopId()}/${img}.jpg`}
              style={{
                width: 50,
                height: 32,
                objectFit: 'cover',
                border: "1px solid #ececec",
                borderRadius: 6,
                cursor: 'pointer',
              }} />
            : <Button
              icon={<CameraOutlined />}
              disabled={disabled}
            />}

        </Upload>
      </Form.Item>
    }}
  </Form.Item>
}

const Product = ({ lang, field, remove, textOnly }) => {
  console.log(ApiShop.baseURL)
  return <div className="product" style={{ marginBottom: 12 }}>
    <div style={{ display: "flex", alignItems: 'center', marginBottom: 12 }}>
      <MenuOutlined className="drag-product" style={{ cursor: 'move', marginRight: 12 }} />
      <Form.Item
        rules={[{ required: true, message: "wymagane" }]}
        name={[field.name, "name", lang]}
        style={{ marginBottom: 0, flex: 1, marginRight: 12 }}
      >
        <Input placeholder={t({ pl: 'Nazwa produktu', en: 'Product name' })} />
      </Form.Item>
      <ImageField name={[field.name, "image"]} disabled={textOnly} />
      <Form.Item
        name={[field.name, "price"]}
        style={{ marginBottom: 0, marginRight: 12, width: 80 }}
      >
        <Input
          placeholder={t({ pl: 'cena', en: 'price' })}
          disabled={textOnly}
        />
      </Form.Item>
      <MinusCircleOutlined
        onClick={() => { remove(field.name) }}
      />
    </div>
    <div style={{ marginLeft: 26 }}>
      <Form.Item
        name={[field.name, "desc", lang]}
      >
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 10 }}
          placeholder={t({ pl: 'Opis', en: 'Description' })}
        />
      </Form.Item>
    </div>
  </div>
}

const Header = ({ lang, field, remove }) => {
  return <div className="product" style={{ marginBottom: 12 }}>
    <div style={{ display: "flex", alignItems: 'center', marginBottom: 12 }}>
      <MenuOutlined className="drag-product" style={{ cursor: 'move', marginRight: 12 }} />
      <Form.Item
        rules={[{ required: true, message: "wymagane" }]}
        name={[field.name, "header", lang]}
        style={{ marginBottom: 0, flex: 1, marginRight: 12 }}
      >
        <Input style={{ fontWeight: 'bold' }} placeholder={t({ pl: 'Nagłówek', en: 'Header' })} />
      </Form.Item>
      <MinusCircleOutlined
        onClick={() => { remove(field.name) }}
      />
    </div>
  </div>
}

const Items = ({ lang, listName, form, textOnly }) => {

  return <Form.List name={listName}>
    {(fields, { add, remove, move }) => <>
      <ReactDragListView
        nodeSelector=".product"
        handleSelector=".drag-product"
        onDragEnd={(from, to) => {
          move(from, to)
        }}
      >

        {fields.map(x => {
          if (form.getFieldValue(["categories", ...listName, x.name, 'name']) != undefined) {
            return <Product
              key={x.name}
              field={x}
              remove={remove}
              lang={lang}
              textOnly={textOnly}
            />
          }
          if (form.getFieldValue(["categories", ...listName, x.name, 'header']) != undefined) {
            return <Header
              key={x.name}
              field={x}
              remove={remove}
              lang={lang}
            />
          }

        })}
      </ReactDragListView>
      {!textOnly ?
        <Row gutter={12}>
          <Col flex={1}>
            <AddButton
              onClick={() => add({ header: { pl: '' } })}
              text={t({ pl: 'Dodaj nagłówek', en: 'Add header' })}
            />
          </Col>
          <Col flex={1}>
            <AddButton
              onClick={() => add({ name: { pl: '' } })}
              text={t({ pl: 'Dodaj produkt', en: 'Add product' })}
            />
          </Col>
        </Row>
        : <div style={{ height: 56 }} />}
    </>}
  </Form.List>

}

const Category = ({ lang, remove, field, form, textOnly }) => {
  const CategoryFields = <div>
    <div style={{ display: "flex", alignItems: 'center' }}>
      <MenuOutlined className="drag-category" style={{ cursor: 'move', marginRight: 12 }} />
      <Form.Item
        rules={[{ required: true, message: "required" }]}
        name={[field.name, "name", lang]}
        style={{ marginBottom: 0, marginRight: 12, flex: 1 }}
      >
        <Input style={{ fontWeight: 'bold' }} placeholder={t({ pl: 'Nazwa kategorii', en: 'Category name' })} />
      </Form.Item>
      <MinusCircleOutlined
        style={{ fontSize: 14 }}
        onClick={() => { remove(field.name) }}
      />
    </div>
    <Form.Item
      name={[field.name, "desc", lang]}
    >
      <Input.TextArea
        autoSize={{ minRows: 2, maxRows: 10 }}
        placeholder={t({ pl: "Opis", en: "Description" })}
        style={{ marginTop: 12 }}
      />
    </Form.Item>
  </div>

  return <Card
    className="category"
    key={field.key}
    style={{
      maxWidth: 600,
      margin: "12px 0",
    }}
    headStyle={{
      paddingLeft: 12,
      paddingRight: 12,
    }}
    title={CategoryFields}
  >
    <Items lang={lang} listName={[field.name, "items"]} form={form} textOnly={textOnly} />
  </Card>
}

const Categories = ({ lang, form, textOnly }) => {
  return <Form.List name="categories">
    {(fields, { add, remove, move }) => <>
      <ReactDragListView
        nodeSelector=".category"
        handleSelector=".drag-category"
        onDragEnd={(from, to) => {
          move(from, to)
        }}
      >
        {fields.map(x => <Category
          key={x.name}
          field={x}
          remove={remove}
          lang={lang}
          form={form}
          textOnly={textOnly}
        />)}
      </ReactDragListView>
      {!textOnly &&
        <AddButton
          onClick={() => add({})}
          text={t({ pl: 'Dodaj kategorię', en: 'Add category' })}
        />
      }
    </>}
  </Form.List>
}

const MainPageConfig = ({ lang }) => {
  return <>
    <Form.Item
      name={["welcome_msg", "pl"]}
      label={t({ pl: "Wiadomość powitalna", en: "Welcome message" })}
      style={{ display: lang == "pl" ? 'block' : 'none' }}
    >
      <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} style={{ maxWidth: 600 }} />
    </Form.Item>
    <Form.Item
      name={["welcome_msg", "en"]}
      label={t({ pl: "Wiadomość powitalna", en: "Welcome message" })}
      style={{ display: lang == "en" ? 'block' : 'none' }}
    >
      <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} style={{ maxWidth: 600 }} />
    </Form.Item>
  </>
}

const MenuPage = ({ menuType }) => {
  const menuReq = useRequest(() => ApiShop.get(`/hotel/menu/${menuType}`))
  const menu = menuReq.data && menuReq.data.data
  const [form] = Form.useForm();
  const [lang, setLang] = useState(t({ pl: 'pl', en: 'en' }))


  const save = (values) => {
    ApiShop.post('/hotel/menu', {
      id: menuType,
      menu: {
        menu_type: menuType,
        ...values,
      }
    })
      .then(() => {
        message.success("Zmiany zapisane")
        //reloadMenu()
      })
      .catch(handleSaveError())
  }

  if (!menu) {
    return <PanelLayout>
      <Spin />
    </PanelLayout>
  }

  const wideScreen = window.innerWidth >= 1280

  return <PanelLayout>
    <Card title={t({ pl: 'Konfiguracja menu restauracji', en: 'Restaurant menu configuration' })}>
      <Form
        form={form}
        onFinish={(values) => {
          console.log(values)
          save(values)
        }}
        initialValues={{
          welcome_msg: { pl: '', en: '' },
          ...menu
        }}
        layout="vertical"
        preserve
      >
        {!wideScreen && <>
          <Row gutter={12} style={{ margin: "12px 0 24px" }}>
            <Col>
              <LangSwitcher lang={lang} setLang={setLang} />
            </Col>
          </Row>
          <Divider orientation="left"></Divider>
          <MainPageConfig lang={lang} />
          <Divider orientation="left">Menu</Divider>
          <Categories lang={lang} form={form} />
        </>}

        {wideScreen &&
        <Row gutter={24}>
          <Col flex={1} style={{ maxWidth: 600 }}>
            <Divider>PL</Divider>
            <MainPageConfig lang="pl" />
            <Categories lang="pl" form={form} />
          </Col>
          <Col flex={1} style={{ maxWidth: 600 }}>
            <Divider>ENG</Divider>
            <MainPageConfig lang="en" />
            <Categories lang="en" form={form} textOnly />
          </Col>
        </Row>}

        <Affix offsetBottom={0}>
          <div style={{
            paddingTop: 12,
            paddingBottom: 12,
            textAlign: 'center',
            borderTop: '1px solid #f0f0f0',
            background: "#fff",
            margin: "0 -24px -12px",
          }}>
            <Button type="primary" htmlType="submit" size="large">
              {t({ pl: 'Zapisz', en: 'Save' })}
            </Button>
          </div>
        </Affix>
      </Form>
    </Card>
  </PanelLayout>
}
export default MenuPage
