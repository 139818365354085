/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */

/*import ProLayout from '@ant-design/pro-layout';
import {
  MenuDataItem,
  Settings,
} from '@ant-design/pro-layout';*/
import React, { useEffect, useState } from 'react';
import { ContainerOutlined, PlusCircleOutlined, LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ShopOutlined, UnorderedListOutlined, QuestionCircleOutlined, SlidersOutlined, CreditCardOutlined, SettingOutlined } from '@ant-design/icons';
import { Result, Button, Tooltip, Avatar, Menu as MenuAnt, Dropdown, Badge, Layout } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import config from '../config'

const withSidebar = window.innerWidth > 768

const NoMatch = (
  <Result
    status={403}
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={
      <Button type="primary">
        <Link to="/user/login">Go Login</Link>
      </Button>
    }
  />
);

const OrdersBadge = inject('state')(observer(({ state }) => {
  const count = state.orders.filter(x => x.status == 'pending').length
  return <Badge count={count} style={{ right: -16 }}>
    <span>&nbsp;</span>
  </Badge>
    ;
}))

const MenuHorizontal = styled(MenuAnt)`
&.ant-menu {
  font-size: 14px;
  background: none;
  display: flex;

  .ant-menu-item {
    color: #283E46;
    font-size: 19px;
    font-weight: 400;
    height: 50px;
    line-height: 50px;
    margin: 0;

    .anticon {
      font-size: 22px;
      vertical-align: middle;
    }
  }
  .ant-menu-submenu {
    margin: 0;
  }
  .ant-menu-item-selected {
    background: rgb(240,242,245);
    font-weight: bold;
  }

  &.ant-menu-inline-collapsed > .ant-menu-item span+span,
  &.ant-menu-horizontal > .ant-menu-item span+span {
    display: none;
  }
  &.ant-menu-inline-collapsed > .ant-menu-item span,
  &.ant-menu-horizontal > .ant-menu-item span {
    margin: 0 !important;
  }

  &.ant-menu-horizontal {
    justify-content: space-between;

    .ant-menu-overflowed-submenu { display: none; }
    .ant-menu-item, .ant-menu-submenu-title {
      padding: 0 10px;
    }
  }

}
`

const MenuVertical = styled(MenuHorizontal)`
&.ant-menu {
  flex-direction: column;
  min-height: calc(100vh - 76px);

  .ant-menu-item {
    margin: 8px 0;
  }
}
`

const Menu = withSidebar ? MenuVertical : MenuHorizontal

const FixedSider = styled.div`
position: fixed;
overflow: auto;
height: 100vh;
width: 290px;
.ant-layout-sider-collapsed & {
  width: 80px;
}
`

const PanelLayout = inject('state')(({ state, children, sidebarContent }) => {
  const [collapsed, setCollapsed] = useState(false)
  const history = useHistory()

  const Icon = ({ name }) => <span className={'icon-' + name} style={{ marginRight: 10, fontSize: '1.4em', verticalAlign: 'middle' }} />

  const MenuContent = () => <Menu
    theme="light"
    mode={withSidebar ? "vertical" : "horizontal"}
    onClick={({ key }) => { history.push(key) }}
    selectedKeys={[history.location.pathname]}
    style={withSidebar ? {paddingBottom: 16} : {}}
    overflowedIndicator={null}
    forceSubMenuRender={true}
  >
    <Menu.Item key="/orders" icon={<Icon name="Cash-register2" />}>
      Zamówienia <OrdersBadge />
    </Menu.Item>
    <Menu.Item key="/add-product" icon={<Icon name="Add" />}>
      Dodawanie produktów
    </Menu.Item>
    <Menu.Item key="/products" icon={<Icon name="Bulleted-List" />}>
      Baza produktów
    </Menu.Item>
    {/*
    <Menu.Item key="/product-options" icon={<SlidersOutlined />}>
      Personalizacja produktów
    </Menu.Item>
    */}
    <Menu.Item key="/shop" icon={<Icon name="Shop" />}>
      Wizytówka biznesu
    </Menu.Item>
    <Menu.Item key="/settings" icon={<Icon name="Gears" />}>
      Ustawienia
    </Menu.Item>
    <Menu.Item key="/payments" icon={<Icon name="Credit-Card" />}>
      <Tooltip title="Dostępne wkrótce">
      Płatności online
      </Tooltip>
    </Menu.Item>

    {withSidebar && <div style={{ flex: 1 }} />}

    <Menu.Item key="/help" icon={<QuestionCircleOutlined/>} style={{color: '#ED6600', fontWeight: 600}}>
      Pomoc
    </Menu.Item>

    <Menu.Item key="logout" onClick={() => state.logout()} icon={<LogoutOutlined/>}>
      Wyloguj
    </Menu.Item>
  </Menu>

  return <Layout style={{ minHeight: '100vh' }}>
    {withSidebar &&
      <Layout.Sider
        collapsible
        breakpoint="md"
        collapsed={collapsed}
        onCollapse={setCollapsed}
        trigger={null}
        style={{
          boxShadow: "2px 0 6px rgba(0, 21, 41, 0.35)",
          background: "#fff",
          zIndex: 1,
        }}
        width={290}
      >
        <FixedSider>
          <div style={{
            padding: "12px 0",
            textAlign: "center",
          }}>
            <Link to="/">
              <img style={{ height: 52 }} src="/logo.png" />
            </Link>
          </div>
          {sidebarContent
            ? sidebarContent
            : <MenuContent />
          }
        </FixedSider>
      </Layout.Sider>
    }
    {!withSidebar && !sidebarContent &&
      <div style={{
        background: "#fff",
      }}>
        <MenuContent />
      </div>
    }
    <Layout className="site-layout">
      <Layout.Content style={{ margin: withSidebar ? '16px' : '16px 8px' }}>
        {children}
      </Layout.Content>
    </Layout>
  </Layout>
})


const t = (x) => {
  const lang = window._state?.lang
  return x[lang] || x['pl'] || ('' + x)
}

const HotelPanelLayout = inject('state')(({ state, children, sidebarContent }) => {
  const [collapsed, setCollapsed] = useState(false)
  const history = useHistory()

  const Icon = ({ name }) => <span className={'icon-' + name} style={{ marginRight: 10, fontSize: '1.4em', verticalAlign: 'middle' }} />

  const MenuContent = () => <Menu
    theme="light"
    mode={withSidebar ? "vertical" : "horizontal"}
    onClick={({ key }) => { history.push(key) }}
    selectedKeys={[history.location.pathname]}
    style={withSidebar ? {paddingBottom: 16} : {}}
    overflowedIndicator={null}
    forceSubMenuRender={true}
  >
    <Menu.Item key="/hotel/menu" icon={<Icon name="Plates" />}>
      {t({pl: 'Menu śniadaniowe', en: 'Breakfast menu'})}
    </Menu.Item>
    <Menu.Item key="/hotel/restaurant-menu" icon={<Icon name="Plates" />}>
      {t({pl: 'Menu restauracyjne', en: 'Restaurant menu'})}
    </Menu.Item>
    {config.servicesMenu &&
      <Menu.Item key="/hotel/services-menu" icon={<Icon name="Slippers" />}>
        {t({pl: 'Usługi', en: 'Services'})}
      </Menu.Item>
    }
    {/*<Menu.Item key="/hotel/settings" icon={<Icon name="Gears" />}>
      Ustawienia
    </Menu.Item>*/}
    <Menu.Item key="/hotel/tables" icon={<Icon name="Chef-Hat" />}>
      {t({pl: 'Dla kuchni', en: 'Kitchen'})}
    </Menu.Item>
    <Menu.Item key="/hotel/orders" icon={<Icon name="Windows-2" />}>
      {t({pl: 'Zamówienia', en: 'Orders'})}
    </Menu.Item>
    <Menu.Item key="/hotel/checkin" icon={<Icon name="QR-Code" />}>
      {t({pl: 'Karty QR', en: 'QR Cards'})}
    </Menu.Item>

    {withSidebar && <div style={{ flex: 1 }} />}

    <Menu.Item key="/hotel/help" icon={<QuestionCircleOutlined/>} style={{color: '#ED6600', fontWeight: 600}}>
      {t({pl: 'Pomoc', en: 'Help'})}
    </Menu.Item>
    <Menu.Item key="logout" onClick={() => state.logout()} icon={<LogoutOutlined/>}>
    {t({pl: 'Wyloguj', en: 'Log out'})}
    </Menu.Item>
  </Menu>

  return <Layout style={{ minHeight: '100vh' }}>
    {withSidebar &&
      <Layout.Sider
        collapsible
        breakpoint="md"
        collapsed={collapsed}
        onCollapse={setCollapsed}
        trigger={null}
        style={{
          boxShadow: "2px 0 6px rgba(0, 21, 41, 0.35)",
          background: "#fff",
          zIndex: 1,
        }}
        width={290}
      >
        <FixedSider>
          <div style={{
            padding: "12px 0",
            textAlign: "center",
          }}>
            <Link to="/">
              <img style={{ maxHeight: 50, maxWidth: 150 }} src={`/api/shop/${state.session.shopId}/image`} />
            </Link>
          </div>
          <MenuContent />
        </FixedSider>
      </Layout.Sider>
    }
    {!withSidebar && !sidebarContent &&
      <div style={{
        background: "#fff",
      }}>
        <MenuContent />
      </div>
    }
    <Layout className="site-layout">
      <Layout.Content style={{ margin: withSidebar ? '16px' : '16px 8px' }}>
        {children}
      </Layout.Content>
    </Layout>
  </Layout>
})

export default PanelLayout;
export { NoMatch, HotelPanelLayout, t }
